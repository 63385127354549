'use client'

import React from 'react'
import {
  Tooltip as RACTooltip,
  TooltipTrigger as RACTooltipTrigger,
  composeRenderProps,
} from 'react-aria-components'
import { tv } from 'tailwind-variants'

import type {
  TooltipProps as RACTooltipProps,
  TooltipTriggerComponentProps as RACTooltipTriggerProps,
} from 'react-aria-components'

import { Text } from '../../typography/text/text'

export interface TooltipProps extends Omit<RACTooltipProps, 'children'> {
  children: React.ReactNode
}

const tooltipStyles = tv({
  base: 'bg-surface border-surface-border text-foreground group rounded border px-3 py-2 text-xs will-change-transform',
  variants: {
    isEntering: {
      true: 'animate-in fade-in placement-bottom:slide-in-from-top-0.5 placement-top:slide-in-from-bottom-0.5 placement-left:slide-in-from-right-0.5 placement-right:slide-in-from-left-0.5 duration-150 ease-out',
    },
    isExiting: {
      true: 'animate-out fade-out placement-bottom:slide-out-to-top-0.5 placement-top:slide-out-to-bottom-0.5 placement-left:slide-out-to-right-0.5 placement-right:slide-out-to-left-0.5 duration-100 ease-in',
    },
  },
})

export function Tooltip({ children, ...props }: TooltipProps) {
  return (
    <RACTooltip
      {...props}
      offset={6}
      className={composeRenderProps(props.className, (className, renderProps) =>
        tooltipStyles({ ...renderProps, className }),
      )}
    >
      <Text size={'1'} color={'current'} className={'leading-tight'}>
        {children}
      </Text>
    </RACTooltip>
  )
}

export type TooltipTriggerProps = RACTooltipTriggerProps

export function TooltipTrigger({
  delay = 300,
  closeDelay = 50,
  children,
  ...rest
}: TooltipTriggerProps) {
  return (
    <RACTooltipTrigger delay={delay} closeDelay={closeDelay} {...rest}>
      {children}
    </RACTooltipTrigger>
  )
}
