'use client'

import React from 'react'
import { Form as RACForm } from 'react-aria-components'

import type { FormProps as RACFormProps } from 'react-aria-components'

import { cn } from '../../utils'

export interface FormProps extends RACFormProps {
  ref?: React.Ref<React.ComponentRef<typeof RACForm>>
}

export function Form({ className, ...rest }: FormProps) {
  return <RACForm className={cn('stack gap-4 text-left', className)} {...rest} />
}
