'use client'

import React from 'react'
import { Slot } from '@radix-ui/react-slot'
import { Check } from 'lucide-react'
import {
  Menu as RACMenu,
  MenuItem as RACMenuItem,
  Separator,
  composeRenderProps,
} from 'react-aria-components'

import type {
  MenuItemProps as RACMenuItemProps,
  MenuProps as RACMenuProps,
  SeparatorProps as RACSeparatorProps,
} from 'react-aria-components'
import type { PopoverProps } from '../../overlays/popover/popover'
import type { DropdownSectionProps } from '../list-box/list-box'

import { Popover } from '../../overlays/popover/popover'
import { cn } from '../../utils'
import { DropdownSection } from '../list-box/list-box'
import { dropdownItemStyles } from '../list-box/list-box.styles'

interface MenuProps<T> extends RACMenuProps<T> {
  placement?: PopoverProps['placement']
}

export function Menu<T extends object>({ className, placement, ...rest }: MenuProps<T>) {
  return (
    <Popover placement={placement} className={cn('min-w-[180px]', className)}>
      <RACMenu
        {...rest}
        className={
          'max-h-[inherit] overflow-auto p-1 outline outline-0 [clip-path:inset(0_0_0_0_round_.75rem)]'
        }
      />
    </Popover>
  )
}

interface MenuItemProps extends RACMenuItemProps {
  icon?: React.ReactNode
  isLink?: boolean
}

export function MenuItem({ icon, children, ...rest }: MenuItemProps) {
  const isLink = rest.href ? true : rest.isLink

  return (
    <RACMenuItem
      {...rest}
      className={composeRenderProps(rest.className, (className, renderProps) =>
        dropdownItemStyles({ ...renderProps, isLink, className }),
      )}
    >
      {composeRenderProps(children, (children, { selectionMode, isSelected }) => (
        <>
          {selectionMode === 'none' ? null : (
            <span className={'flex w-3.5 items-center'}>
              {isSelected ? <Check aria-hidden className={'size-3.5'} /> : null}
            </span>
          )}

          {icon ? (
            <span className={'flex w-3.5 items-center'}>
              <Slot className={'size-3.5'}>{icon}</Slot>
            </span>
          ) : null}

          <span
            className={
              'group-selected:font-semibold flex flex-1 items-center gap-2 truncate font-medium'
            }
          >
            {children}
          </span>
        </>
      ))}
    </RACMenuItem>
  )
}

export function MenuSeparator(props: RACSeparatorProps) {
  return <Separator {...props} className={'border-neutral-4 mx-3 my-1 border-b'} />
}

export function MenuSection<T extends object>(props: DropdownSectionProps<T>) {
  return <DropdownSection {...props} />
}
