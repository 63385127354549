'use client'

import React from 'react'
import { TextField as RACTextField, composeRenderProps } from 'react-aria-components'

import type {
  TextFieldProps as RACTextFieldProps,
  ValidationResult as RACValidationResult,
} from 'react-aria-components'

import { cn } from '../../utils'
import { Description, FieldError, Input, Label } from '../field/field'
import { fieldBorderStyles, fieldStyles } from '../field/field.styles'

export interface TextFieldProps extends RACTextFieldProps {
  label?: string
  hideLabel?: boolean
  description?: string | React.ReactNode
  errorMessage?: string | ((validation: RACValidationResult) => string) | React.ReactNode
  placeholder?: string
  ref?: React.Ref<React.ComponentRef<typeof Input>>
}

export function TextField({
  className,
  description,
  errorMessage,
  label,
  ref,
  ...rest
}: TextFieldProps) {
  return (
    <RACTextField
      {...rest}
      className={composeRenderProps(className, (className, renderProps) =>
        fieldStyles({ ...renderProps, className }),
      )}
    >
      {label ? <Label className={cn({ 'sr-only': rest.hideLabel })}>{label}</Label> : null}

      <Input ref={ref} className={fieldBorderStyles} placeholder={rest.placeholder} />

      {description ? <Description>{description}</Description> : null}
      {rest.isDisabled ? null : <FieldError>{errorMessage}</FieldError>}
    </RACTextField>
  )
}
