import React from 'react'
import { Outlet, Link as RouterLink, useMatches } from '@tanstack/react-router'

import { LogoMark } from '@fysioscout/ui/misc/logo-mark'
import { Link as UILink } from '@fysioscout/ui/navigation/link'
import { ThemeSelector } from '@fysioscout/widgets/theme'

import { Route as ForgottenPasswordRoute } from '@/app/routes/_unauth/forgotten-password'
import { Route as OtpRoute } from '@/app/routes/_unauth/otp'

export function AppLayout() {
  const matches = useMatches()

  const pathsWithBackButton = new Set<string>([ForgottenPasswordRoute.to, OtpRoute.to])
  const showBackButton = matches.some((match) => pathsWithBackButton.has(match.pathname))

  return (
    <div className={'@container relative'}>
      <ThemeSelector className={'absolute right-5 top-5 print:hidden'} />

      <div className={'stack @xl:max-w-lg @xl:center mx-auto min-h-screen'}>
        <div
          className={
            'border-modal-border bg-modal stack @md:p-8 @xl:h-auto @xl:flex-none @xl:items-center @xl:rounded-2xl @xl:border @xl:p-10 size-full flex-1 items-start p-5'
          }
        >
          <div className={'bg-accent @xl:p-5 mb-14 rounded-2xl p-4'}>
            <LogoMark className={'@xl:w-12 w-8 fill-white'} />
          </div>

          <div className={'w-full'}>
            <Outlet />

            {showBackButton ? (
              <UILink intent={'back'} size={'xs'} className={'mt-8'}>
                <RouterLink to={'/login'}>Tilbage</RouterLink>
              </UILink>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  )
}
